@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Nunito+Sans:ital,opsz,wght@0,6..12,200;1,6..12,200&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Brygada+1918&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Protest+Riot&display=swap');

/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Brygada+1918:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Apply fonts globally */
@layer base {
  body {
    @apply font-dmsans;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-brygada;
  }
}

body {
  scroll-behavior: smooth;
}

div {
  border-radius: 5px;
}

@keyframes dropAnimation {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.drop-container {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  animation: dropAnimation cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-duration: 0.4s;
}

h1 {
  font-family: 'Brygada 1918', sans-serif;
}